import axios from 'axios';
import { clearLocalStore } from '../localStorage/index'
import { Cookies } from 'react-cookie'
const APIURL = process.env.REACT_APP_BACKEND_URL_API
const tenantId= process.env.REACT_APP_HEADER_TEANANT_TOKEN
const cookies = new Cookies()
// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        clearLocalStore(true);
        setTimeout(()=>{
            window.location = '/';
        },100)
      
    } else {
        return Promise.reject(error);
    }
});


export const apiCall = async (path, method, data, sizesCheck) => {
    //console.log({apiurl:APIURL,path,method,data:JSON.stringify(data)})
    var headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }

    // if (localStorage.getItem(process.env.REACT_APP_TOKEN_AUTHENTICATION_KEY) !== null) 
    if (cookies.get('token') && cookies.get('token') !== null) {

        headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${cookies.get('token')}`
        }
        //console.log('userinfo:2-> ' + JSON.stringify(headers))
    }

    if(tenantId !== 'undefined'){
        headers.tenantId=tenantId;
    }
    //console.table(headers)
    if (method === 'POST') {

        data.device = 1;

        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,
            data: data
        }).then(response => {
            // console.log("API response", response)

            return response;

        }).catch(error => {

            // console.log("API Error", error)

            return Promise.reject({
                code: error.code,
                message: error.message,
                name: error.name
            });
        })
    } else {
        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,

        }).then(response => {
            //console.log("API response", response)

            return response;

        }).catch(error => {

            // console.log("API Error", error)
            return Promise.reject({
                code: error.code,
                message: error.message,
                name: error.name
            });
        })
    }

    return res;
}

export const apiUploadCall = async (path, files, setPercentage) => {
    //console.log(`${APIURL}${path} path ${path} data ${JSON.stringify(files)}`)

    function getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    var formData = new FormData();
    // formData.append('name','test.png')
    formData.append('file', { uri: files.filePath, name: files.fileName, type: files.contentType });

    var headers = {
        "Content-Type": 'multipart/form-data'
    }

    if(tenantId !== 'undefined'){
        headers.tenantId=tenantId;
    }

    const onUploadProgress = (progressEvent) => {

        //console.log('progress ', (progressEvent.loaded / progressEvent.total) * 99)

        setPercentage((file) => {
            file[files.index].progress = (progressEvent.loaded / progressEvent.total) * 99;

            //console.log('progressEvent: ',file[files.index])
            return [...file]
        })
        // setPercentage(99);

    }

    const data = formData;
    // console.log('formData ', formData);
    // console.log('headers', headers)


    var res = await axios({
        method: 'POST',
        url: `${APIURL}${path}`,
        headers,
        // onUploadProgress,
        data: files,

    }).then(response => {

        // console.log("API Response", response)


        return {
            ...response.data,
            file_path: `${response.data.storage}${response.data.file_path}`,

        };

    }).catch(error => {
        // console.log("API Error", error)
        return error.response;
    })


    return res;
}

export const logoutUser = async () => {
    axios.defaults.headers = {

    };
    clearLocalStore(true);

    return true;
}