import { toast } from "react-toastify";

let options = {
  current: 'toast-options',
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  limit: 1,
  id:'toast-options'
};

let options2 = {
  position: "bottom-center",
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
  id:'toast-options-2'
}

export function orderToast(message) {
  toast.success(message, options2);
}
export function orderError(message) {
  toast.error(message, options2);
}
export function success(message, rqOption) {
  if (!toast.isActive(options.current)) {
    if (rqOption) {
      options = {
        ...options,
        ...rqOption
      }
    }
    options.current = toast.success(message, options);
  }
}

export function error(message) {
  if (!toast.isActive(options.current)) {
    options.current = toast.error(message, options);
  }
}

export function warn(message) {
  if (!toast.isActive(options.current)) {
    options.current = toast.warn(message, options);
  }
}

export function info(message) {

  if (!toast.isActive(options.current)) {
    options.current = toast.info(message, options);
  }
}
