import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import WithLoading from "../WithLoading";
import { useSelector } from "react-redux";
import { signalRInitiated } from '../../helper/hook/signalR'

const MainLayout = WithLoading(
  React.lazy(() => import("../layout/Main.layout"))
);

// pages with loading
const DashboardIndexPage = WithLoading(React.lazy(() => import("../../page/Dashboard/Dashboard.page")));
const PageNotFound = WithLoading(React.lazy(() => import("../../page/Error/404")));
const UpdatePasswordPage = WithLoading(React.lazy(() => import("../../page/Auth/UpdatePassword.page")))
const PaymentPage = WithLoading(React.lazy(() => import("../../page/Payment/Payment.page")))
const CheckoutPage = WithLoading(React.lazy(() => import("../../page/Checkout/CheckoutPage")))
const MyAccountPage = WithLoading(React.lazy(() => import("../../page/MyAccount/MyAccount.page")))

const RequireAuth = (props) => {
  return (
    <>
      {" "}
      {props.active ? (
        props.children
      ) : (
        <Navigate to="/" replace />
      )}{" "}
    </>
  );
};

const ProtectedLayout = () => {
  const stateAuth = useSelector(state => state.authReducer)
  const { user } = stateAuth;
  return (
    <RequireAuth active={!user.isLoggedIn}>
      <MainLayout />
    </RequireAuth>
  )
}

const ProtectedLayoutIslogin = () => {
  const stateAuth = useSelector(state => state.authReducer)
  const { user } = stateAuth;
  if (user.isLoggedIn) {
    signalRInitiated()
  }
  return (
    <RequireAuth active={user.isLoggedIn}>
      <MainLayout />
    </RequireAuth>
  )
}
const AppRouters = (props) => {

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<DashboardIndexPage />} />
      </Route>
      <Route path=":menuTypeSlug" element={<MainLayout />}>
        <Route index element={<DashboardIndexPage />} />
      </Route>
      <Route path=":menuTypeSlug/:categorySlug" element={<MainLayout />}>
        <Route index element={<DashboardIndexPage />} />
      </Route>
      <Route path=":menuTypeSlug/:categorySlug/:subCategorySlug" element={<MainLayout />}>
        <Route index element={<DashboardIndexPage />} />
      </Route>
    
      <Route path="*" element={<PageNotFound />} />
     
      {/*if Login page */}
      <Route path="/" element={<ProtectedLayoutIslogin />}>
        <Route index path="updatePassword" element={<UpdatePasswordPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/order/:status" element={<PaymentPage />} />
        {/* <Route path="/order/confirmation" element={<ConfirmationPage />} /> */}
      </Route>
      <Route path="/myaccount" element={<ProtectedLayoutIslogin />}>
        <Route index path=":menu" element={<MyAccountPage />} />
      </Route>
    </Routes >
  );
};

export default AppRouters;
