import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from '../../API';
import * as toast from '../../components/Toast'

export const getMenuTypeServicesACTION = createAsyncThunk(
    'MenuTypeService/getMenuTypeServices',
    async (data) => {

        return API.apiCall("/restaurant/web/getmenutypeservices", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const isValidateServiceACTION = createAsyncThunk(
    'MenuTypeService/isValidateService',
    async (data) => {
        let d = {
            "serviceID": data.serviceID,
            "serviceType": data.serviceType,
            "latitude": data.latitude,
            "longitude": data.longitude
        }
        return API.apiCall("/restaurant/web/validateservice", "POST", { ...d }, false)
            .then((response) => {

                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)

                        return {
                            ...data,
                            ...response.data.data,
                            ...response.data.message

                        }
                    } else {
                        if (!data.notShowToast) {
                            toast.error(response.data.message.message)
                        }

                        return Promise.reject({
                            code: response.data.message.code,
                            message: response.data.message.message,
                            type: response.data.message.type
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const startOrderACTION = createAsyncThunk(
    'MenuTypeService/startOrder',
    async (data) => {

        return API.apiCall("/restaurant/web/startorder", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);

export const restartOrderACTION = createAsyncThunk(
    'MenuTypeService/restartOrder',
    async (data) => {

        return API.apiCall("/restaurant/web/restartorder", "POST", data, false)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (!response.data.hasError) {
                        // toast.success(response.data.message.message)
                        return {
                            ...response.data
                        }
                    } else {
                        toast.error(response.data.message.message)
                        return Promise.reject({
                            ...response.data.message
                        })
                    }
                }
            })
            .catch((error) => {

                return Promise.reject({
                    ...error
                })
            })
    }
);