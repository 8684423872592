import { createSlice } from '@reduxjs/toolkit'
import {
    getCartDetailsACTION,
    addToCartACTION,
    removeCartItemACTION,
    placeOrderACTION,
    getOrderDetailACTION,
    getAllUserOrderACTION,
    paymentConfirmationACTION,
    applyPromocodeACTION,
    removePromocodeACTION
} from '../actions/order.Actions'
import { StorageKeys, setCookieStoreObject } from '../../localStorage';

const INITIAL_STATE = {
    cartDetail: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    cartDetailWithAppliedPromocode: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    mutableCart: {
        data: [],
        count: 0
    },
    addToCardDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    placeOrder: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    },
    orderDetail: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    },
    allUserOrder: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    },
    paymentConfirmation: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        }
    }
};

const orderReducer = createSlice({
    name: 'Order',
    initialState: INITIAL_STATE,
    reducers: {

        QUANTITY_UPDATE(state, action) {
            state.mutableCart.data[action.payload.index].quantity = action.payload.quantity;
        },
        RESET_PAYMENT_CONFIRMATION(state, action) {
            state.paymentConfirmation = INITIAL_STATE.paymentConfirmation;
            state.placeOrder = INITIAL_STATE.placeOrder;
        },
        RESET_ORDER_DETAIL(state, action) {
            state.orderDetail = INITIAL_STATE.orderDetail;
        },
        RESET_PLACEORDER(state, action) {
            state.placeOrder = INITIAL_STATE.placeOrder;
        },
        REMOVE_FROM_CART(state, action) {
            state.mutableCart.data = state.mutableCart.data.filter((item) => item.cartItemID !== action.payload.cartItemID)
            state.mutableCart.count = state.mutableCart.data.filter((item) => item.cartItemID !== action.payload.cartItemID).length
        },
        RESETCARTDETAIL(state, action) {
            state.mutableCart = INITIAL_STATE.mutableCart;
            state.cartDetail = INITIAL_STATE.cartDetail;
        },
        ResetAddToCartDetails(state, action) {
            state.addToCardDetails = INITIAL_STATE.cartDetail;
        },
        ResetCartDetailsWithAppliedPromocode(state, action) {
            state.cartDetailWithAppliedPromocode = INITIAL_STATE.cartDetailWithAppliedPromocode;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getCartDetailsACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getCartDetailsACTION.fulfilled, (state, action) => {
            //console.log("getCartDetailsACTION", action);
            state.cartDetail.data = action.payload.data
            state.mutableCart.data = action.payload.data.items
            state.mutableCart.count = action.payload.data.items.length
            state.cartDetail.apiMsg.status = action.meta.requestStatus;
            state.cartDetail.apiMsg.message = action.payload.message.message;
            setCookieStoreObject(StorageKeys.ServiceDetails, state.cartDetail.data);
        }),
            builder.addCase(getCartDetailsACTION.pending, (state, action) => {
                //console.log("getCartDetailsACTION pending", action);
                state.cartDetail.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getCartDetailsACTION.rejected, (state, action) => {
                // console.log("getCartDetailsACTION rejected", action);
                state.cartDetail.apiMsg.message = action.error.message;
                state.cartDetail.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getCartDetailsACTION end -------------------------
            //------------------------------- addToCartACTION start -------------------------
            builder.addCase(addToCartACTION.fulfilled, (state, action) => {
                state.addToCardDetails.data = action.payload.data
                state.mutableCart.count = state.mutableCart.count + 1
                state.addToCardDetails.apiMsg.status = action.meta.requestStatus;
                state.addToCardDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(addToCartACTION.pending, (state, action) => {
                //console.log("getCartDetailsACTION pending", action);
                state.addToCardDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(addToCartACTION.rejected, (state, action) => {
                // console.log("getCartDetailsACTION rejected", action);
                state.addToCardDetails.apiMsg.message = action.error.message;
                state.addToCardDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- addToCartACTION end -------------------------
            //------------------------------- removeCartItemACTION start -------------------------
            builder.addCase(removeCartItemACTION.fulfilled, (state, action) => {
                state.cartDetail.data = action.payload.data
                state.mutableCart.data = action.payload.data.items
                state.mutableCart.count = action.payload.data.items.length
                state.addToCardDetails.apiMsg.status = action.meta.requestStatus;
                state.addToCardDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(removeCartItemACTION.pending, (state, action) => {
                //console.log("removeCartItemACTION pending", action);
                state.addToCardDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(removeCartItemACTION.rejected, (state, action) => {
                // console.log("removeCartItemACTION rejected", action);
                state.addToCardDetails.apiMsg.message = action.error.message;
                state.addToCardDetails.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- removeCartItemACTION end -------------------------
            //------------------------------- placeOrderACTION start -------------------------
            builder.addCase(placeOrderACTION.fulfilled, (state, action) => {
                state.placeOrder.data = action.payload.data
                state.placeOrder.apiMsg.status = action.meta.requestStatus;
                state.placeOrder.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(placeOrderACTION.pending, (state, action) => {
                //console.log("placeOrderACTION pending", action);
                state.placeOrder.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(placeOrderACTION.rejected, (state, action) => {
                // console.log("placeOrderACTION rejected", action);
                state.placeOrder.apiMsg.message = action.error.message;
                state.placeOrder.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- placeOrderACTION end -------------------------
            //------------------------------- getOrderDetailACTION start -------------------------
            builder.addCase(getOrderDetailACTION.fulfilled, (state, action) => {
                state.orderDetail.data = action.payload.data
                state.orderDetail.apiMsg.status = action.meta.requestStatus;
                state.orderDetail.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getOrderDetailACTION.pending, (state, action) => {
                //console.log("getOrderDetailACTION pending", action);
                state.orderDetail.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getOrderDetailACTION.rejected, (state, action) => {
                // console.log("getOrderDetailACTION rejected", action);
                state.orderDetail.apiMsg.message = action.error.message;
                state.orderDetail.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getOrderDetailACTION end -------------------------
            //------------------------------- getAllUserOrderACTION start -------------------------
            builder.addCase(getAllUserOrderACTION.fulfilled, (state, action) => {
                state.allUserOrder.data = action.payload.data
                state.allUserOrder.apiMsg.status = action.meta.requestStatus;
                state.allUserOrder.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getAllUserOrderACTION.pending, (state, action) => {
                //console.log("getAllUserOrderACTION pending", action);
                state.allUserOrder.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllUserOrderACTION.rejected, (state, action) => {
                // console.log("getAllUserOrderACTION rejected", action);
                state.allUserOrder.apiMsg.message = action.error.message;
                state.allUserOrder.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getAllUserOrderACTION end -------------------------
            //------------------------------- paymentConfirmationACTION start -------------------------
            builder.addCase(paymentConfirmationACTION.fulfilled, (state, action) => {
                state.paymentConfirmation.data = action.payload.data
                state.paymentConfirmation.apiMsg.status = action.meta.requestStatus;
                state.paymentConfirmation.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(paymentConfirmationACTION.pending, (state, action) => {
                //console.log("paymentConfirmationACTION pending", action);
                state.paymentConfirmation.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(paymentConfirmationACTION.rejected, (state, action) => {
                // console.log("paymentConfirmationACTION rejected", action);
                state.paymentConfirmation.apiMsg.message = action.error.message;
                state.paymentConfirmation.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- paymentConfirmationACTION end -------------------------
            //------------------------------- applyPromocodeACTION start -------------------------
            builder.addCase(applyPromocodeACTION.fulfilled, (state, action) => {
                state.cartDetailWithAppliedPromocode.data = action.payload.data
                state.cartDetailWithAppliedPromocode.apiMsg.status = action.meta.requestStatus;
                state.cartDetailWithAppliedPromocode.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(applyPromocodeACTION.pending, (state, action) => {
                state.cartDetailWithAppliedPromocode.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(applyPromocodeACTION.rejected, (state, action) => {
                console.log("applyPromocodeACTION rejected", action);
                state.cartDetailWithAppliedPromocode.apiMsg.message = action.error.message;
                state.cartDetailWithAppliedPromocode.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- applyPromocodeACTION start -------------------------
            builder.addCase(removePromocodeACTION.fulfilled, (state, action) => {
                state.cartDetailWithAppliedPromocode.data = action.payload.data
                state.cartDetailWithAppliedPromocode.apiMsg.status = action.meta.requestStatus;
                state.cartDetailWithAppliedPromocode.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(removePromocodeACTION.pending, (state, action) => {
                state.cartDetailWithAppliedPromocode.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(removePromocodeACTION.rejected, (state, action) => {
                console.log("removePromocodeACTION rejected", action);
                state.cartDetailWithAppliedPromocode.apiMsg.message = action.error.message;
                state.cartDetailWithAppliedPromocode.apiMsg.status = action.meta.requestStatus;
            })
    }
})

export const { QUANTITY_UPDATE, RESET_PAYMENT_CONFIRMATION, RESET_ORDER_DETAIL, RESET_PLACEORDER, REMOVE_FROM_CART, RESETCARTDETAIL, ResetAddToCartDetails, ResetCartDetailsWithAppliedPromocode } = orderReducer.actions;
export default orderReducer.reducer;