import { createSlice } from '@reduxjs/toolkit'
import {
  getTokenACTION,
  signupACTION,
  validateOTPACTION,
  resendOTPACTION,
  fpvalidateOTPACTION,
  loginACTION,
  changePasswordACTION,
  forgotPasswordACTION,
  updatePasswordACTION,
  saveAdderssACTION,
  getAllAdderssACTION,
  changestatusAdderssACTION,
  deleteAdderssACTION
} from '../actions/auth.Actions'
import { Cookies } from 'react-cookie'
const cookies = new Cookies()

const token = cookies.get('token');
const usersInfo = JSON.parse(localStorage.getItem("usersInfo"));

const INITIAL_STATE = {
  user: token && usersInfo != null ? {
    isLoggedIn: true,
    usersInfo: usersInfo,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  } : {
    isLoggedIn: false,
    usersInfo: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  tokenDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  logingCall: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  singupDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  changePassword: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  forgotPasswordDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  forgotPasswordValidateOTPCall: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  resendOTPDetails: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  updatePassword: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  authModal: {
    show: false,
    login: false,
    signup: false,
    validate: false,
    forgotPassword: false,
    passwordValidateOTP: false,
  },
  address: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  },
  addressDetail: {
    data: null,
    apiMsg: {
      message: null,
      status: null,
      error: null
    }
  }
};

const authReducer = createSlice({
  name: 'Authentication',
  initialState: INITIAL_STATE,
  reducers: {
    SET_ADDRESS_DETAIL(state, action) {
      state.addressDetail.data = action.payload
    },
    SET_AUTH_MODAL(state, action) {
      state.authModal = action.payload
    },
    RESET_AUTH_MODAL(state, action) {
      state.authModal = INITIAL_STATE.authModal
      state.logingCall = INITIAL_STATE.logingCall
    },
    RESET_USER(state, action) {
      state.user = {
        isLoggedIn: false,
        usersInfo: null,
        apiMsg: {
          message: null,
          status: null,
          error: null
        }
      };
    },
    RESET_SINGUP_DETAILS(state, action) {
      state.singupDetails = INITIAL_STATE.singupDetails;
    },
    RESET_FORGOT_DETAILS(state, action) {
      state.forgotPasswordDetails = INITIAL_STATE.forgotPasswordDetails;
    },
    RESET_FORGOT_VALIDATION(state, action) {
      state.forgotPasswordValidateOTPCall = INITIAL_STATE.forgotPasswordValidateOTPCall;
    },
    RESET_UPDATE_PASSWORD(state, action) {
      state.updatePassword = INITIAL_STATE.updatePassword;
    },
    RESET_RESENDOTP(state, action) {
      state.resendOTPDetails = INITIAL_STATE.resendOTPDetails
    },
    RESET_ADDRESSDETAIL(state, action) {
      state.addressDetail = INITIAL_STATE.addressDetail
    },
    RESET_ALL(state, action) {
      state.singupDetails = INITIAL_STATE.singupDetails;
      state.logingCall = INITIAL_STATE.logingCall;
      state.user = INITIAL_STATE.user;
      state.forgotPasswordDetails = INITIAL_STATE.forgotPasswordDetails;
      state.updatePassword = INITIAL_STATE.updatePassword;
      state.resendOTPDetails = INITIAL_STATE.resendOTPDetails;
      state.addressDetail = INITIAL_STATE.addressDetail;
    }
  },
  extraReducers: (builder) => {
    //------------------------------- getTokenACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(getTokenACTION.fulfilled, (state, action) => {
      //console.log("getTokenACTION", action);
      state.tokenDetails.data = action.payload.data;
      state.tokenDetails.apiMsg.status = action.meta.requestStatus;
      state.tokenDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(getTokenACTION.pending, (state, action) => {
        //console.log("getTokenACTION pending", action);
        state.tokenDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getTokenACTION.rejected, (state, action) => {
        // console.log("getTokenACTION rejected", action);
        state.tokenDetails.apiMsg.message = action.error.message;
        state.tokenDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getTokenACTION end -------------------------
      //------------------------------- signupACTION start -------------------------
      builder.addCase(signupACTION.fulfilled, (state, action) => {
        //console.log("signupACTION", action);
        state.singupDetails.data = {
          userID: action.payload.data.user.userId,
          user: action.meta.arg.mobileNo,
          validateOTP: action.payload.data.validateOTP ,
        };
        state.singupDetails.apiMsg.status = action.meta.requestStatus;
        state.singupDetails.apiMsg.message = action.payload.message.message;
        //console.log("action.payload.data", action.payload.data)
        if (!action.payload.data.validateOTP) {
          state.user.isLoggedIn = true;
          state.user.usersInfo = action.payload.data;
          state.singupDetails.data = action.payload.data;
          //state.singupDetails.data.data = action.payload.data;
          state.logingCall.apiMsg.status = action.meta.requestStatus;
          state.logingCall.apiMsg.message = action.payload.message.message;
          localStorage.setItem('main', action.payload.data.tokenDetails?.token)
          localStorage.setItem('usersInfo', JSON.stringify({ user: action.payload.data.user }))
          let date = new Date(action.payload.data.tokenDetails?.expires)
          const cookies = new Cookies()
          cookies.set('token', action.payload.data.tokenDetails?.token, { path: '/', expires: date });
          state.singupDetails.data.isLoggedIn = true;
        }


      }),
      builder.addCase(signupACTION.pending, (state, action) => {
        //console.log("signupACTION pending", action);
        state.singupDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(signupACTION.rejected, (state, action) => {
        // console.log("signupACTION rejected", action);
        state.singupDetails.apiMsg.message = action.error.message;
        state.singupDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- signupACTION end -------------------------
      //------------------------------- validateOTPACTION start -------------------------
      builder.addCase(validateOTPACTION.fulfilled, (state, action) => {
        //console.log("validateOTPACTION", action);
        state.user.isLoggedIn = true;
        state.user.usersInfo = action.payload.data;
        state.singupDetails.data = action.payload.data;
        state.singupDetails.apiMsg.status = action.meta.requestStatus;
        state.singupDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(validateOTPACTION.pending, (state, action) => {
        //console.log("validateOTPACTION pending", action);
        state.singupDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(validateOTPACTION.rejected, (state, action) => {
        // console.log("validateOTPACTION rejected", action);
        state.singupDetails.apiMsg.message = action.error.message;
        state.singupDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- validateOTPACTION end -------------------------
      //------------------------------- fpvalidateOTPACTION start -------------------------
      builder.addCase(fpvalidateOTPACTION.fulfilled, (state, action) => {
        //console.log("fpvalidateOTPACTION", action);

        state.forgotPasswordValidateOTPCall.data = action.payload.data;
        state.forgotPasswordValidateOTPCall.apiMsg.status = action.meta.requestStatus;
        state.forgotPasswordValidateOTPCall.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(fpvalidateOTPACTION.pending, (state, action) => {
        //console.log("fpvalidateOTPACTION pending", action);
        state.forgotPasswordValidateOTPCall.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(fpvalidateOTPACTION.rejected, (state, action) => {
        // console.log("fpvalidateOTPACTION rejected", action);
        state.forgotPasswordValidateOTPCall.apiMsg.message = action.error.message;
        state.forgotPasswordValidateOTPCall.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- fpvalidateOTPACTION end -------------------------
      //------------------------------- resendOTPACTION start -------------------------
      builder.addCase(resendOTPACTION.fulfilled, (state, action) => {
        //console.log("resendOTPACTION", action);
        state.resendOTPDetails.data = action.payload.data;
        state.resendOTPDetails.apiMsg.status = action.meta.requestStatus;
        state.resendOTPDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(resendOTPACTION.pending, (state, action) => {
        //console.log("resendOTPACTION pending", action);
        state.resendOTPDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(resendOTPACTION.rejected, (state, action) => {
        // console.log("resendOTPACTION rejected", action);
        state.resendOTPDetails.apiMsg.message = action.error.message;
        state.resendOTPDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- resendOTPACTION end -------------------------
      //------------------------------- loginACTION start -------------------------
      builder.addCase(loginACTION.fulfilled, (state, action) => {
        //console.log("loginACTION", action);
        state.user.isLoggedIn = true;
        state.user.usersInfo = action.payload.data;
        state.singupDetails.data = action.payload.data;
        state.logingCall.apiMsg.status = action.meta.requestStatus;
        state.logingCall.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(loginACTION.pending, (state, action) => {
        //console.log("loginACTION pending", action);
        state.logingCall.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(loginACTION.rejected, (state, action) => {
        // console.log("loginACTION rejected", action);
        state.logingCall.apiMsg.message = action.error.message;
        state.logingCall.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- changePasswordACTION start -------------------------
      builder.addCase(changePasswordACTION.fulfilled, (state, action) => {
        //console.log("updatePasswordACTION", action);
        state.changePassword.data = action.payload.data;
        state.changePassword.apiMsg.status = action.meta.requestStatus;
        state.changePassword.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changePasswordACTION.pending, (state, action) => {
        //console.log("updatePasswordACTION pending", action);
        state.changePassword.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(changePasswordACTION.rejected, (state, action) => {
        // console.log("updatePasswordACTION rejected", action);
        state.changePassword.apiMsg.message = action.error.message;
        state.changePassword.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- loginACTION end -------------------------
      //------------------------------- forgotPasswordACTION start -------------------------
      builder.addCase(forgotPasswordACTION.fulfilled, (state, action) => {
        // console.log("forgotPasswordACTION", action);
        
        state.forgotPasswordDetails.data = {
          userID: action.payload.data.user.userId,
          user: action.meta.arg.mobileNo,
          validateOTP:action.payload.data.validateOTP
        };
        state.forgotPasswordDetails.apiMsg.status = action.meta.requestStatus;
        state.forgotPasswordDetails.apiMsg.message = action.payload.message.message;

        if(!action.payload.data.validateOTP){
          state.forgotPasswordValidateOTPCall.data = action.payload.data;
          state.forgotPasswordValidateOTPCall.apiMsg.status = action.meta.requestStatus;
          state.forgotPasswordValidateOTPCall.apiMsg.message = action.payload.message.message;
        }
       

      }),
      builder.addCase(forgotPasswordACTION.pending, (state, action) => {
        //console.log("forgotPasswordACTION pending", action);
        state.forgotPasswordDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(forgotPasswordACTION.rejected, (state, action) => {
        // console.log("forgotPasswordACTION rejected", action);
        state.forgotPasswordDetails.apiMsg.message = action.error.message;
        state.forgotPasswordDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- forgotPasswordACTION end -------------------------
      //------------------------------- updatePasswordACTION start -------------------------
      builder.addCase(updatePasswordACTION.fulfilled, (state, action) => {
        //console.log("updatePasswordACTION", action);
        state.updatePassword.data = action.payload.data;
        state.updatePassword.apiMsg.status = action.meta.requestStatus;
        state.updatePassword.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(updatePasswordACTION.pending, (state, action) => {
        //console.log("updatePasswordACTION pending", action);
        state.updatePassword.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(updatePasswordACTION.rejected, (state, action) => {
        // console.log("updatePasswordACTION rejected", action);
        state.updatePassword.apiMsg.message = action.error.message;
        state.updatePassword.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getAllAdderssACTION end -------------------------
      builder.addCase(getAllAdderssACTION.fulfilled, (state, action) => {
        //console.log("getAllAdderssACTION", action);
        state.address.data = action.payload.data;
        state.address.apiMsg.status = action.meta.requestStatus;
        state.address.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getAllAdderssACTION.pending, (state, action) => {
        //console.log("getAllAdderssACTION pending", action);
        state.address.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getAllAdderssACTION.rejected, (state, action) => {
        // console.log("getAllAdderssACTION rejected", action);
        state.address.apiMsg.message = action.error.message;
        state.address.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getAllAdderssACTION end -------------------------
      //------------------------------- saveAdderssACTION end -------------------------
      builder.addCase(saveAdderssACTION.fulfilled, (state, action) => {
        // console.log("saveAdderssACTION", action.payload);
        if (!action.payload.newRecord) {
          let updatedRecord = state.address.data.records.map(x => {
            let isDefault = action.meta.arg.isDefault
            if (x.id == action.meta.arg.id) {

              return { ...action.meta.arg, isDefault };
            } else if (isDefault) {
              return {
                ...x,
                isDefault: false
              }
            }
            else {
              return x;
            }
          })
          state.address.data.records = updatedRecord;
        }
        else {
          if (state.address.data.records.length) {
            state.address.data.records.unshift({ ...action.meta.arg, id: action.payload.data.data, });
          }
          else {
            state.address.data.records.push({ ...action.meta.arg, id: action.payload.data.data, });
          }
        }

        action.meta.arg.id = action.payload.data.data;
        state.addressDetail.data = action.meta.arg;
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
        state.addressDetail.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(saveAdderssACTION.pending, (state, action) => {
        //console.log("saveAdderssACTION pending", action);
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(saveAdderssACTION.rejected, (state, action) => {
        // console.log("saveAdderssACTION rejected", action);
        state.addressDetail.apiMsg.message = action.error.message;
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- saveAdderssACTION end -------------------------
      //------------------------------- changestatusAdderssACTION end -------------------------
      builder.addCase(changestatusAdderssACTION.fulfilled, (state, action) => {
        //console.log("changestatusAdderssACTION", action);
        state.address.data.records = state.address.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id ? !item.active : item.active
          }
        })
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
        state.addressDetail.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changestatusAdderssACTION.pending, (state, action) => {
        //console.log("changestatusAdderssACTION pending", action);
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(changestatusAdderssACTION.rejected, (state, action) => {
        // console.log("changestatusAdderssACTION rejected", action);
        state.addressDetail.apiMsg.message = action.error.message;
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- changestatusAdderssACTION end -------------------------
      //------------------------------- deleteAdderssACTION end -------------------------
      builder.addCase(deleteAdderssACTION.fulfilled, (state, action) => {
        // console.log("deleteAdderssACTION", action);
        state.address.data.records = state.address.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.addressDetail.apiMsg.status = action.meta.requestStatus;
        state.addressDetail.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(deleteAdderssACTION.pending, (state, action) => {
        //console.log("deleteAdderssACTION pending", action);
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteAdderssACTION.rejected, (state, action) => {
        // console.log("deleteAdderssACTION rejected", action);
        state.addressDetail.apiMsg.message = action.error.message;
        state.addressDetail.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- deleteAdderssACTION end -------------------------
  }
})

export const {
  SET_ADDRESS_DETAIL,
  SET_AUTH_MODAL,
  RESET_AUTH_MODAL,
  RESET_USER,
  RESET_SINGUP_DETAILS,
  RESET_FORGOT_DETAILS,
  RESET_FORGOT_VALIDATION,
  RESET_UPDATE_PASSWORD,
  RESET_RESENDOTP,
  RESET_ADDRESSDETAIL,
  RESET_ALL
} = authReducer.actions;
export default authReducer.reducer;