import { createSlice } from '@reduxjs/toolkit'
import { getProductsACTION, getProductDetailsACTION } from '../actions/products.Actions'

const INITIAL_STATE = {
    products: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    },
    productArrange: {
        data: []
    },
    productsDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            error: null
        },
    }

};

const productsReducer = createSlice({
    name: 'Products',
    initialState: INITIAL_STATE,
    reducers: {
        SET_PRODUCT_SETUP(state, action) {

            var tmpProducts = [];
            if (action.payload.products) {
                tmpProducts = JSON.parse(JSON.stringify(action.payload.products));

            }
            // console.log('SET_PRODUCT_SETUP ', action);
            var allCategoriesData = [];
            //console.log("action.payload.products", action.payload.products)
            if (action.payload.filters && action.payload.filters.length) {
                var searchParam = action.payload.filters.filter(x => x.key === "search").length ? action.payload.filters.filter(x => x.key === "search")[0].value : null;
                var tagParam = action.payload.filters.filter(x => x.key === "tag").length ? action.payload.filters.filter(x => x.key === "tag")[0].value : null;
                if (searchParam) {

                    //console.log("action.payload.products",action.payload.products)
                    action.payload.products.map((category) => {

                        var allCategories = JSON.parse(JSON.stringify(allCategoriesData));

                        if (category.categoryName.toLowerCase().includes(searchParam.toLowerCase())) {
                            allCategories.push(category);
                        }
                        else {
                            var objCategory = {
                                categoryID: category.categoryID,
                                categoryName: category.categoryName,
                                slug: category.slug,
                                products: []
                            }

                            //var productWithoutSubCategory = category.products.filter(x=>!x.subCategoryID);
                            //console.log("productWithoutSubCategory",productWithoutSubCategory);

                            category.products.map((product) => {


                                if (product.name.toLowerCase().includes(searchParam.toLowerCase())) {

                                    if (allCategories && allCategories.length && allCategories.findIndex(x => x.categoryID == product.categoryID) !== -1) {
                                        allCategories[allCategories.findIndex(x => x.categoryID == product.categoryID)].products.push(product);
                                    }
                                    else {
                                        allCategories.push({
                                            categoryID: category.categoryID,
                                            categoryName: category.categoryName,
                                            slug: category.slug,
                                            products: [product]
                                        })
                                    }

                                }



                            })




                        }
                        //console.log("allCategories",allCategories)
                        allCategoriesData = allCategories;

                    })
                }
                else {

                    allCategoriesData = JSON.parse(JSON.stringify(action.payload.products));
                }
                if (tagParam) {
                    var tagCatregories = [];
                    allCategoriesData.map((category) => {
                        category.products.map((product) => {
                            if (product.tags && product.tags.length) {
                                var selectedTagProduct = product.tags.filter(x => x.code === tagParam);
                                if (selectedTagProduct && selectedTagProduct.length) {

                                    if (tagCatregories && tagCatregories.length && tagCatregories.findIndex(x => x.categoryID == product.categoryID) !== -1) {
                                        tagCatregories[tagCatregories.findIndex(x => x.categoryID == product.categoryID)].products.push(product);
                                    }
                                    else {
                                        tagCatregories.push({
                                            categoryID: category.categoryID,
                                            categoryName: category.categoryName,
                                            slug: category.slug,
                                            products: [product]
                                        })
                                    }
                                }

                            }
                        });
                    });

                    //console.log("tagCatregories", tagCatregories)

                    allCategoriesData = tagCatregories;
                    //console.log("allCategories", allCategoriesData)
                    state.productArrange.data = allCategoriesData;

                }

                if (allCategoriesData.length) {
                    //console.log("allCategories", allCategoriesData);
                    //state.productArrange.data = allCategoriesData;

                    tmpProducts = JSON.parse(JSON.stringify(allCategoriesData));

                }
                else{
                    tmpProducts = [];
                }

            }

            var productItemList = [];
            if (tmpProducts.length) {
                tmpProducts.map((category, i) => {
                    var productWithoutSubCategory = category.products.filter(x => !x.subCategoryID);
                    var tempProductWithSubCategory = category.products.filter(x => x.subCategoryID).sort((a, b) => (a.subCategorySortOrder > b.subCategorySortOrder) ? 1 : -1);

                    const groupByCategory = tempProductWithSubCategory.reduce((group, subCategory) => {
                        const { subCategoryID } = subCategory;
                        group[subCategoryID] = group[subCategoryID] ?? [];
                        group[subCategoryID].push(subCategory);
                        return group;
                    }, {});
                    //console.log("groupByCategory", groupByCategory)
                    var productWithSubCategory = [];
                    if (Object.keys(groupByCategory) && Object.keys(groupByCategory).length) {
                        Object.keys(groupByCategory).map((gpItem) => {

                            var productWithSubCategoryItems = groupByCategory[gpItem] && groupByCategory[gpItem].length ? groupByCategory[gpItem].sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1) : groupByCategory[gpItem];

                            productWithSubCategory.push({
                                subCategoryId: groupByCategory[gpItem][0].subCategoryID,
                                subCategoryName: groupByCategory[gpItem][0].subCategoryName,
                                items: productWithSubCategoryItems
                            });
                        })
                    }

                    productItemList.push({
                        categoryID: category.categoryID,
                        categoryName: category.categoryName,
                        categoryDescription: category.categoryDescription,
                        items: productWithoutSubCategory,
                        subCategories: productWithSubCategory
                    })

                })

                //console.log("productItemList",productItemList);
            }
            state.productArrange.data = productItemList;


        },
        RESET_PRODUCTSDETAILS(state, action) {
            state.productsDetails = INITIAL_STATE.productsDetails;
        },
        RESET_PRODUCT_STATE(state, action) {
            state.products = INITIAL_STATE.products;
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getProductsACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getProductsACTION.fulfilled, (state, action) => {
            //console.log("getProductsACTION", action);
            state.products.data = action.payload.data
            state.products.apiMsg.status = action.meta.requestStatus;
            state.products.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getProductsACTION.pending, (state, action) => {
                //console.log("getProductsACTION pending", action);
                state.products.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getProductsACTION.rejected, (state, action) => {
                // console.log("getProductsACTION rejected", action);
                state.products.apiMsg.message = action.error.message;
                state.products.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getProductsACTION end -------------------------
            //------------------------------- getProductDetailsACTION start -------------------------

            builder.addCase(getProductDetailsACTION.fulfilled, (state, action) => {
                //console.log("getProductDetailsACTION", action);
                state.productsDetails.data = action.payload.data
                state.productsDetails.apiMsg.status = action.meta.requestStatus;
                state.productsDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getProductDetailsACTION.pending, (state, action) => {
                //console.log("getProductDetailsACTION pending", action);
                state.productsDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getProductDetailsACTION.rejected, (state, action) => {
                // console.log("getProductDetailsACTION rejected", action);
                state.productsDetails.apiMsg.message = action.error.message;
                state.productsDetails.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getProductDetailsACTION end -------------------------
    }
})

export const { SET_PRODUCT_SETUP, RESET_PRODUCTSDETAILS,RESET_PRODUCT_STATE } = productsReducer.actions;
export default productsReducer.reducer;