export const ReducerStateContant ={
    fulfilled:'fulfilled',
    pending:'pending',
    rejected:'rejected'
}
export const ValidationExpression = {
    Email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
    URL :/^(https?:\/\/(?:www\.)?)?([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?$/,
    //Password:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/
    Password:{
        Expression:/^(?=\D*\d)(?=[^a-zA-Z]*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{6,}$/,
        Message:"Password must contain minimum 6 characters with at least one letter and one number"
    }

};

