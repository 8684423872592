import React, { Suspense } from "react";
import {PlaceholderLoader} from "../components/Loader.component"

const WithLoading = (Component) => {
  return (props) => (
    <div>
      <Suspense fallback={<PlaceholderLoader />}>
        <Component {...props} />
      </Suspense>
    </div>
  );
};



export default WithLoading;
