import React, { useState, useRef } from 'react'
import AppRouters from './navigation/router/AppRouters'
import { useSelector, useDispatch } from 'react-redux';
import { getTokenACTION } from './redux-store/actions/auth.Actions'
import { webGetStaticConfigurationACTION,getWebConfigurationACTION, getTableWithServiceDetailsACTION, getPOSuserGoogleReviewURL } from './redux-store/actions/webConfig.Actions'
import { startOrderACTION } from './redux-store/actions/menuTypeService.Actions';
import * as gfun from './helper/functions/general-func'
import { PlaceholderLoader } from './components/Loader.component';
import { ReducerStateContant } from './helper/constants/general-const';
import { useParams, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { getCartDetailsACTION } from './redux-store/actions/order.Actions.js';
import { StorageKeys, clearLocalStore, getLocalStoreObject } from './localStorage';
import { Modal, Form, Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { WebContext } from './web-context';

function App() {
  const stateAuth = useSelector(state => state.authReducer);
  const isLoggedIn = stateAuth.user.isLoggedIn;
  const stateWebConfig = useSelector(state => state.webConfigReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authorizedAccess, setAuthorizedAccess] = React.useState(ReducerStateContant.pending);
  const params = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();
  const stateMenuTypeService = useSelector(state => state.menuTypeServiceReducer);
  const [cookies, setCookie,] = useCookies(['cartID']);
  const [askTable, setAskTable] = useState(false);
  const tableNumberRef = useRef(null);
  const [tableNumber, setTableNumber] = useState(null);
  const [callTableOrderService, setCallTableOrderService] = useState(false);
  const [websiteSetttings, setWebsiteSetttings] = useState(null);


  // get hostname and subDomain
  let origin = window.location;
  let subDomain = null;
  if (process.env.REACT_APP_FIXED_DOMAIN !== 'undefined') {
    subDomain = process.env.REACT_APP_FIXED_DOMAIN;
  } else {
    subDomain = origin.hostname.split('.')[0];
  }
  useEffect(() => {
    dispatch(webGetStaticConfigurationACTION());
  }, [])

  // for get token
  React.useEffect(() => {
    if (!gfun.GetCookie("token")) {
      dispatch(getTokenACTION({ subDomain }))
    }
    if (!gfun.GetCookie("cartID")) {
      setCallTableOrderService(true);
    }

  }, [location.pathname, stateMenuTypeService.menuConfigServiceReset, stateWebConfig.menuSelect.id])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const actionType = searchParams.get("actionref");
    const reftoken = searchParams.get("reftoken");
    if (actionType === "pougrqrscan" && reftoken) {
      dispatch(getPOSuserGoogleReviewURL({ device: 1, qrCodeToken: reftoken }))
    }

  }, [location]);


  useEffect(() => {
    if (gfun.StateCompleted(stateWebConfig.POSUserGoogleReviewURLdata)) {
      if (stateWebConfig.POSUserGoogleReviewURLdata.data && stateWebConfig.POSUserGoogleReviewURLdata.data.googleReviewUrl) {
        window.location.href = stateWebConfig.POSUserGoogleReviewURLdata.data.googleReviewUrl;
      }
      else {
        navigate("/");
        window.location.reload();
      }
    }
  }, [stateWebConfig.POSUserGoogleReviewURLdata])

  //for get web configuration settings
  React.useEffect(() => {
    if (searchParam.get("actionref") && !gfun.GetCookie('cartID')) {
      if (gfun.StateCompleted(stateAuth.tokenDetails) || gfun.GetCookie("token")) {
        if (searchParam.get("actionref") === "diqrscan") {
          if (searchParam.get("reftoken")) {
            //console.log("call getTableWithServiceDetailsACTION")
            if (searchParam.get("asktbl") && searchParam.get("asktbl") == "true") {
              setAskTable(true);
            }
            else {
              dispatch(getTableWithServiceDetailsACTION({ tableID: searchParam.get("reftoken") }));
            }

          }

        }
      }
    }
    else {
      getWebConfigurations();
    }
  }, [stateAuth.tokenDetails, stateMenuTypeService.menuConfigServiceReset, callTableOrderService])

  React.useEffect(() => {
    if (gfun.StateCompleted(stateWebConfig.tableWithServiceDetails) && gfun.StateHasData(stateWebConfig.tableWithServiceDetails)) {
      getWebConfigurations();
    }

  }, [stateWebConfig.tableWithServiceDetails])

  React.useEffect(() => {
    //console.log("stateWebConfig.tableWithServiceDetails", stateWebConfig.tableWithServiceDetails)
    if (gfun.StateCompleted(stateWebConfig.tableWithServiceDetails) && gfun.StateHasData(stateWebConfig.tableWithServiceDetails) && gfun.StateCompleted(stateWebConfig.webConfiguration) && gfun.StateHasData(stateWebConfig.webConfiguration) && stateWebConfig.menuSelect.id) {
      startTableOrder(stateWebConfig.tableWithServiceDetails.data);
    }
  }, [stateWebConfig.webConfiguration, stateWebConfig.menuSelect.id])

  React.useEffect(() => {

    if (gfun.StateCompleted(stateMenuTypeService.menuConfigService) && gfun.StateHasData(stateMenuTypeService.menuConfigService)) {

      setCookie("cartID", stateMenuTypeService.menuConfigService.data.cartID, {
        path: "/",
        maxAge: 15 * 60
      });
      if (gfun.GetCookie('cartID')) {
        console.log("getCartDetailsACTION 1")
        dispatch(getCartDetailsACTION({ id: stateMenuTypeService.menuConfigService.data.cartID }))
      }

    }
  }, [stateMenuTypeService.menuConfigService])

  function startTableOrder(data) {
    var rqData = {
      "menuTypeID": stateWebConfig.menuSelect.id,
      "serviceID": data.serviceID,
      "serviceType": data.serviceType,
      orderType: 1
    }
    if (tableNumber) {
      rqData.tableNumber = tableNumber;

    }
    if (searchParam.get("reftoken")) {
      rqData.tableID = searchParam.get("reftoken");
    }
    dispatch(startOrderACTION({ ...rqData }))
  }


  function getWebConfigurations() {
    if (gfun.StateCompleted(stateAuth.tokenDetails) || gfun.GetCookie("token")) {
      var currentMenutype = !gfun.pathAuthCheck(location.pathname) ? origin.pathname.split("/") : "";
      currentMenutype = searchParam.get("id") ? "" : currentMenutype;
      var serviceID = gfun.StateCompleted(stateWebConfig.tableWithServiceDetails) && gfun.StateHasData(stateWebConfig.tableWithServiceDetails) ? stateWebConfig.tableWithServiceDetails.data.serviceID : null;
      if (!serviceID && getLocalStoreObject(StorageKeys.ServiceDetails)) {
        serviceID = getLocalStoreObject(StorageKeys.ServiceDetails).serviceID;
      }
      dispatch(getWebConfigurationACTION({ currentMenutype: currentMenutype && currentMenutype.length ? currentMenutype[1] : null, serviceID: serviceID }))
    }
  }
  useEffect(() => {
    if (gfun.StateCompleted(stateWebConfig.webStaticConfiguration)) {
      if (stateWebConfig.webStaticConfiguration.data && stateWebConfig.webStaticConfiguration.data[window.location.hostname]) {
        setWebsiteSetttings(stateWebConfig.webStaticConfiguration.data[window.location.hostname]);
      }
      else {
        setWebsiteSetttings(stateWebConfig.webStaticConfiguration.data.default);
      }
    }
   
  }, [stateWebConfig.webStaticConfiguration]);
  return (
    stateWebConfig ? <>
      <WebContext.Provider value={websiteSetttings}>

      {
        gfun.StateCompleted(stateWebConfig.webConfiguration) ?

          <AppRouters authorizedAccess={authorizedAccess} active={isLoggedIn} />

          :
          <PlaceholderLoader />
      }
      <Modal size="sm" show={askTable} backdrop="static"
        keyboard={false}>
        <Modal.Header className='gpnt-modal-header justify-content-center p-3'>
          <h5 className='m-0 text-uppercase fw-bold'>Table Number</h5>
        </Modal.Header>
        <Modal.Body className='pb-3 pt-0'>
          <Form.Group >
            <Form.Control
              type="text"
              placeholder="TABLE NO"
              autoFocus
              className='shadow-none'
              ref={tableNumberRef}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className='justify-content-center border-0 p-0 pb-3'>

          <Button variant="primary" className='btn-theme-primary' onClick={() => {

            if (tableNumberRef.current.value) {
              tableNumberRef.current.classList.remove("field-error")
              setTableNumber(tableNumberRef.current.value);
              setAskTable(false);
              dispatch(getTableWithServiceDetailsACTION({ tableID: searchParam.get("reftoken") }));
            }
            else {
              tableNumberRef.current.classList.add("field-error")
            }

          }}>
            PROCEED
          </Button>
        </Modal.Footer>
      </Modal>
      </WebContext.Provider>
      </> : <h1>Please wait...</h1>
       )
}

export default App