/* eslint-disable import/first */
const signalR = require("@microsoft/signalr");

export var connection = null;

export function signalRInitiated(restaurantID) {
    connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_BACKEND_URL}/notify`)
        .configureLogging(signalR.LogLevel.Information)
        .build();
    connection.start().catch(err => console.error(err.toString()));
}